import React from 'react';
import Spinner from "components/Spinner"
import loadable from '@loadable/component';

const LoadableLaminarFlow = loadable(() => import('components/LaminarFlowHoodCertificationPage'), {
  fallback: (
    <Spinner />
  ),
});

const LaminarFlowPage = () => {
  return <LoadableLaminarFlow />;
};

export default LaminarFlowPage;
